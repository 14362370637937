import React, { Component } from 'react'
import ErrorIndicator from './ErrorIndicator'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: '',
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: errorInfo,
    })
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return <ErrorIndicator error={this.error} />
    }

    return this.props.children
  }
}
export default ErrorBoundary
