import { faBug } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Button, Card } from 'react-bootstrap'

const ErrorIndicator = (props) => {
  const refresh = () => {
    window.location.reload()
  }
  return (
    <div className='d-flex vh-100 justify-content-center align-items-center'>
      <Card
        bg={'light'}
        className='d-flex justify-content-center align-items-center p-5'
      >
        <FontAwesomeIcon
          className='mt-1'
          size={'10x'}
          icon={faBug}
        />
        <Card.Body className='d-flex flex-column justify-content-center align-items-center p-4'>
          <Card.Title className='fs-1'>Упс... что-то пошло не так</Card.Title>
          <Card.Text className='fs-5'>Мы уже чиним...</Card.Text>
          <Card.Text className='fs-5'>{props.error}</Card.Text>
          <Button onClick={refresh}>Перезагрузить страницу</Button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ErrorIndicator
