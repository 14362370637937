import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Outlet} from 'react-router-dom'
import {useAuth} from '../context/provideAuth'
import {useLocalStorage} from '../hooks/useLocalStorage'
import request from '../tools/ajaxManager'
import '../firebase-messaging-sw'
import PopupToast from '../components/Notifications/PopupToast'

import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported} from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyC1dl-cu4rrVcTcIDlLgYjS6100iTjq06Y",
    authDomain: "smurno-7030a.firebaseapp.com",
    projectId: "smurno-7030a",
    storageBucket: "smurno-7030a.appspot.com",
    messagingSenderId: "981123498715",
    appId: "1:981123498715:web:2f38f5f6aec53f5f2e02fe"
};

const Layout = () => {
    const auth = useAuth()
    const [FCMToken] = useLocalStorage('gis-river-fcm-token')
    const [FCMTokenOld, setFCMTokenOld] = useLocalStorage(
        'gis-river-fcm-token-old'
    )
    const dispatch = useDispatch()
    const matchMedia = window.matchMedia('(max-width: 768px)')
    const matchMediaTablet = window.matchMedia(
        '(min-width: 769px) and (max-width: 1279px)'
    )
    const matchMediaDesktop = window.matchMedia('(min-width: 1280px)')

    useEffect(() => {
        if (isSupported()) {
            const app = initializeApp(firebaseConfig);
            const isFirebaseSupported = async () => {
                const supported = await isSupported(); // imported from 'firebase/messaging'
                return supported ? getMessaging(app) : null;
            }

            isFirebaseSupported().then(messaging => {
                console.log('Requesting permission...');
                if (messaging !== null) Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                        getToken(messaging, {vapidKey: "BAIcsDt_JCNyQ9ulI_DcrwCzA__hcxInvTgn9qRb_Z-CAnPZv1V8DvAfeslzDUdIl96b3OYKlkU_Utn3hitRlcw"}).then(token => {
                            console.log('PUSH token !!!', token)

                            if (token !== FCMTokenOld) {
                                const data = {
                                    fcmToken: token,
                                    fcmOld: FCMTokenOld,
                                }

                                request(
                                    '/user/fcmtoken',
                                    'PATCH',
                                    data,
                                    {
                                        'YT-AUTH-TOKEN': `YourTar ${auth.token}`,
                                    },
                                    (response) => {
                                        setFCMTokenOld(FCMToken)
                                    },
                                    (error) => {
                                        console.error(error)
                                    }
                                )
                            }
                        });
                    }
                })
            })
        }
    }, [auth])

    useEffect(() => {
        handleResizeMobile()
        handleResizeTablet()
        handleResizeDesktop()

        matchMedia.addEventListener('change', () => handleResizeMobile())
        matchMediaTablet.addEventListener('change', () => handleResizeTablet())
        matchMediaDesktop.addEventListener('change', () => handleResizeDesktop())

        return () => {
            matchMedia.removeEventListener('change', () => handleResizeMobile())
            matchMediaTablet.removeEventListener('change', () => handleResizeTablet())
            matchMediaDesktop.removeEventListener('change', () =>
                handleResizeDesktop()
            )
        }
    }, [])

    const handleResizeMobile = () => {
        if (matchMedia.matches) {
            dispatch({type: 'TOGGLE', payload: true})
        }
    }

    const handleResizeTablet = () => {
        if (matchMediaTablet.matches) {
            dispatch({type: 'TOGGLE_TABLET', payload: true})
        }
    }

    const handleResizeDesktop = () => {
        if (matchMediaDesktop.matches) {
            dispatch({type: 'TOGGLE_DESKTOP', payload: true})
        }
    }

    return (
        <div className='app'>
            <PopupToast/>

            <Outlet/>
        </div>
    )
}

export default Layout
