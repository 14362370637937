import React, {lazy, Suspense, useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import projectStorage from './reducers/rootReducer';
import './i18n';
import LanguageContext from './context/provideLanguage';
import {CookiesProvider, useCookies} from 'react-cookie';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/_global.scss';

const SpinnerPage = lazy(() => import('./components/SpinnerPage'));

const container = document.getElementById('root');
const root = createRoot(container);

const Main = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['locale']);
    const [locale, setLocale] = useState('ru');

    useEffect(() => {
        setLocale(cookies.locale);
    }, [cookies]);

    return (
        // <React.StrictMode>
        <ErrorBoundary>
            <CookiesProvider>
                <Provider store={projectStorage.store}>
                    <PersistGate persistor={projectStorage.persistor}>
                        <LanguageContext.Provider value={{locale, setLocale}}>
                            <Suspense loading={<SpinnerPage/>}>
                                <App/>
                            </Suspense>
                        </LanguageContext.Provider>
                    </PersistGate>
                </Provider>
            </CookiesProvider>
        </ErrorBoundary>
        // </React.StrictMode>
    );
};

root.render(<Main/>);

// if ('serviceWorker' in navigator) {
//     serviceWorkerRegistration.register(
//         navigator.serviceWorker
//             .register('../firebase-messaging-sw.js')
//             .then(function (registration) {
//                 console.log('Registration successful, scope is:', registration.scope);
//             })
//             .catch(function (err) {
//                 console.log('Service worker registration failed, error:', err);
//             })
//     );
// }
