import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import React, {lazy, Suspense, useEffect} from 'react'
import ProvideAuth from './context/provideAuth'
import Layout from './routes/Layout'
import NoMatch from './pages/NoMatch'
import {useDispatch, useSelector} from 'react-redux'
import request from './tools/ajaxManager'
import 'leaflet/dist/leaflet.css';

const PublicRoutes = lazy(() => import('./routes/Public'))
const PrivateRoutes = lazy(() => import('./routes/Private'))
const SpinnerPage = lazy(() => import('./components/SpinnerPage'))

const App = () => {
    const {user} = useSelector((state) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user && user.token) {
            request(
                `/user/`,
                'GET',
                {},
                {
                    'YT-AUTH-TOKEN': `YourTar ${user.token}`,
                },
                function (response) {
                    if (response.data) {
                        dispatch({type: 'UPDATE', payload: response.data})
                    }
                },
                function (error) {
                    alert(error.message)
                }
            )
        }
    }, [])

    return <>
        <iframe id="ifmcontentstoprint" style={{height: 0, width: 0, position: "absolute"}}></iframe>
        <ProvideAuth>
            <Router>
                <Suspense fallback={<SpinnerPage/>}>
                    <Routes>
                        <Route path='/' element={<Layout/>}>
                            <Route index element={<Navigate to='/login' replace/>}/>

                            <Route
                                path='admin/*'
                                element={
                                    user.user ? <PrivateRoutes/> : <Navigate to='/login'/>
                                }
                            />

                            <Route path='/*' element={<PublicRoutes/>}/>

                            <Route path='*' element={<NoMatch to='/login'/>}/>
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </ProvideAuth>
    </>
}

export default App
